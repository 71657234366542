import React from 'react';
import Image from 'react-bootstrap/Image';
import {LinkContainer} from 'react-router-bootstrap';
import {Nav, Navbar, NavLink} from "react-bootstrap";

export default function Header() {
    return (
        <div>
            <a href='https://enobo.com/'>
                <Image src="/Enobo-Logo-2048.jpg" fluid />
            </a>
            <Navbar bg="dark" variant="dark" expand="sm">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to="/home">
                            <NavLink>Home</NavLink>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
