import React from 'react';
import {Container} from "react-bootstrap";

export default function Home() {
    return (
        <Container className="mt-3">
            <h1>Coming Soon</h1>
        </Container>
    )
}
