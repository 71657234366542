import React from 'react';
import './App.css';
import Header from './components/Header';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
        <Header />
        <Switch>
            <Route path="/home">
                <Home />
            </Route>
            <Route path="/" exact>
                <Redirect to="/home"/>
            </Route>
        </Switch>
    </BrowserRouter>
  );
}

export default App;
